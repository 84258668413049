import {
  Box,
  Heading,
  Text,
  Image,
  Grid,
  GridItem,
  Flex,
} from "@chakra-ui/react";

import { MainContainer } from "../../layout";

import Salah from "../../assets/images/salah.svg";
import Dhikr from "../../assets/images/dhikr.svg";
import Quran from "../../assets/images/quran.svg";

export const Companion = () => {
  const options = [
    {
      id: 1,
      title: "Praying Salah",
      body: "Salah is a very important obligation as it allows you to communicate with Allah. It is also the first matter you will be asked about on the Day of Judgement.",
      image: Salah,
    },
    {
      id: 2,
      title: "Remembering Allah (Dhikr)",
      body: "Dhikr is a very powerful way of strengthening your relationship with Allah. It will be a light for you in your grave and on the Day of Judgement.",
      image: Dhikr,
    },
    {
      id: 3,
      title: "Reciting the Quran",
      body: "Reciting the Quran is a very noble act that brings you closer to Allah. You get rewarded for it as it becomes a witness for you on the Day of Judgement.",
      image: Quran,
    },
  ];

  return (
    <Box py={{ base: "60px", lg: "100px" }}>
      <MainContainer bg="brand.white">
        <Box 
          w={{ base: "100%", lg: "50%" }} 
          mb={{ base: "48px", lg: "80px" }}
          >
          <Heading fontSize={{ base: "32px", lg: "60px" }} color="brand.black" pr="7vw">
            The perfect companion for your spiritual life
          </Heading>

          <Text
            fontSize={{ base: "1rem", xl: "1.2rem" }}
            color="brand.gray"
            mt="1rem"
          >
            With Hudi, it's like having a spiritual companion in your palm/wrist, 
            guiding you in the right direction to build daily habits in these 3 
            essential spiritual practices.
          </Text>
        </Box>

        <Grid
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr 1fr" }}
          gap={"32px"}
        >
          {options.map((option) => (
            <GridItem key={option.id}>
              <Flex
                gap={{ base: "24px", lg: "16px" }}
                flexDir={{ base: "column", lg: "row" }}
              >
                <Image
                  src={option.image}
                  w={"60px"}
                  h={"60px"}
                  borderRadius={"30px"}
                />

                <Box flex={1}>
                  <Heading
                    fontSize={{ base: "20px", lg: "24px" }}
                    color={"brand.black"}
                    mb="16px"
                  >
                    {option.title}
                  </Heading>

                  <Text
                    fontSize={{ base: "1rem", xl: "1.2rem" }}
                    color={"brand.gray"}
                  >
                    {option.body}
                  </Text>
                </Box>
              </Flex>
            </GridItem>
          ))}
        </Grid>
      </MainContainer>
    </Box>
  );
};
