import {
  Box,
  Heading,
  Text,
  Image,
  Grid,
  GridItem,
  Flex,
} from "@chakra-ui/react";

import { MainContainer } from "../../layout";

import ProgressTracking from "../../assets/images/progressTracking.png";

export const TrackProgress = () => {
  const calculatedHeight3 = `calc(50vh - 5rem - 38px)`;
  const calculatedHeight4 = `calc(60vh - 5rem - 38px)`;

  return (
    <Box>
      <MainContainer bg="brand.yellow">
        <Grid
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          gap={"32px"}
          pt={{ base: "60px", lg: "0px" }}
        >
          <GridItem
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            px={{ base: 0, lg: "5vw" }}
          >
            <Heading
              fontSize={{ base: "32px", lg: "60px" }}
              color="brand.black"
              mb="10px"
            >
              Track your progress trends overtime
            </Heading>

            <Text fontSize={{ base: "1rem", xl: "1.25rem" }} color="brand.gray">
              See how you're doing on your habits to give you an insight on
              what’s going well and what you need to improve on to stay
              consistent.
            </Text>
          </GridItem>

          <GridItem>
            <Flex
              align={{ base: "flex-start", lg: "center" }}
              justify="center"
              h={{ base: calculatedHeight4, sm: "620px" }}
              w={"auto"}
              overflow={'hidden'}
            >
              <Image
                src={ProgressTracking}
                alt="phone"
                objectFit={"cover"}
                h={{ base: "140%", sm: "140%" }}
                w={"auto"}
              />
            </Flex>
          </GridItem>
        </Grid>
      </MainContainer>
    </Box>
  );
};
