export const colors = {
  brand: {
    white: "#FFFFFF",
    black: "#000000",
    gray: "#333333",
    gray2: "#8E8E93",
    lightgray: "#F2F2F2",
    neutral: {
      50: "#E6E6E6",
    },
    green: {
      200: "rgba(86, 220, 141, 0.08)",
      400: "#56DC8D",
    },
    purple: "rgba(137, 89, 244, 0.08)",
    purple2: "#8959F4",
    yellow: "rgba(255, 204, 2, 0.08)",
    yellow2: "#FFCC02",
  },
};
