import axios from "axios";
import { v4 as uuidv4 } from "uuid";

export const generateUserIdentifier = (): string => {
  // Generate a UUID
  const userId = uuidv4();

  // Optionally, store the UUID in localStorage to persist it across visits
  if (!localStorage.getItem("userId")) {
    localStorage.setItem("userId", userId);
  }

  return localStorage.getItem("userId") || userId;
};

export const makePostRequest = async (data: any) => {
  try {
    const response = await axios.post(
      "https://hudi-backend-v2.onrender.com/mixpanel",
      data,
      {
        headers: {},
      }
    );

    return response.data;
  } catch (error: any) {
    console.error(error);
    // throw error.message || "An error occurred";
  }
};

export const mixPanelTag = async (
  tag: string,
  actionType: "Page Visit" | "Button Click"
) => {
  const userAgentString = navigator.userAgent;

  await makePostRequest({
    deviceId: generateUserIdentifier(),
    deviceName: userAgentString,
    deviceModel: "Web",
    tag,
    actionType,
  });
};
