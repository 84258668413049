import { Box, Heading, Text, Image, Flex, HStack } from "@chakra-ui/react";

import { mixPanelTag } from "../../queries";
import { MainContainer } from "../../layout";

import AppStore from "../../assets/images/appStore.png";
import PlayStore from "../../assets/images/playStore.png";

export const Download = () => {
  const tagDownloadButton = async (os: "ios" | "android") => {
    mixPanelTag(
      os === "ios" ? "W_IOS_DOWNLOAD" : "W_ANDROID_DOWNLOAD",
      "Button Click"
    );
  };

  return (
    <Box>
      <MainContainer bg="brand.black">
        <Flex
          px={{ base: "0", lg: "30%" }}
          py={{ base: "60px", lg: "100px" }}
          flexDirection="column"
          align={{ base: "flex-start", lg: "center" }}
          justify={{ base: "flex-start", lg: "center" }}
        >
          <Heading
            fontSize={{ base: "36px", lg: "64px" }}
            color="brand.white"
            textAlign={{ base: "left", lg: "center" }}
          >
            Take a step towards Jannah
          </Heading>

          <Text
            fontSize={{ base: "1rem", xl: "1.25rem" }}
            color="brand.white"
            mt="1rem"
            textAlign={{ base: "left", lg: "center" }}
          >
            Download the Hudi app and get the guidance and motivation you need
            for that journey.
          </Text>

          <HStack 
            mt={{ base: "1.5rem", lg: "2.3rem" }} 
            spacing="24px"
            >
            <a
              href={`https://apps.apple.com/ng/app/hudi-build-jannah-habits/id6469621563`}
              target="_blank"
              rel="noreferrer"
              onClick={() => tagDownloadButton("ios")}
            >
              <Image src={AppStore} alt="" h={{ base: "40px", lg: "52px" }} />
            </a>

            <a
              href={`https://play.google.com/store/apps/details?id=com.gethudi.mobile`}
              target="_blank"
              rel="noreferrer"
              onClick={() => tagDownloadButton("android")}
            >
              <Image src={PlayStore} alt="" h={{ base: "40px", lg: "52px" }} />
            </a>
          </HStack>
        </Flex>
      </MainContainer>
    </Box>
  );
};
