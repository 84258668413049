import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home, Privacy, Terms, WhyHudi } from "./pages";

export const WebRoutes = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route index path="/" element={<Home />} />
          <Route path="why-hudi" element={<WhyHudi />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="terms" element={<Terms />} />
        </Routes>
      </Router>
    </>
  );
};
