import { Heading, Flex, Image } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { mixPanelTag } from "../queries";
import { MainContainer } from "../layout";
import Logo from "../../src/assets/images/logo.png";
import Logo2 from "../../src/assets/images/logo2.svg";

export const Header = ({ bg }: any) => {
  const navigate = useNavigate();

  const whyHudi = () => {
    mixPanelTag("W_WHY_HUDI", "Button Click")
    navigate("/why-hudi");
  };

  return (
    <MainContainer bg={bg}>
      <Flex
        pos="sticky"
        align="center"
        justifyContent="space-between"
        bg="transparent"
        zIndex={100}
        h="5rem"
      >
        <Image
          src={bg === "brand.black" ? Logo : Logo2}
          h="28px"
          onClick={() => navigate("/")}
          cursor={"pointer"}
        />

        <Heading
          fontSize={16}
          color={bg === "brand.black" ? "brand.white" : "brand.black"}
          cursor={"pointer"}
          onClick={whyHudi}
        >
          Why Hudi?
        </Heading>
      </Flex>
    </MainContainer>
  );
};
