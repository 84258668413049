import { Flex, HStack, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import { mixPanelTag } from "../queries";
import { MainContainer } from "../layout";

export const Footer = () => {
  const navigate = useNavigate();

  const tag = (tag: string) => {
    mixPanelTag(tag, "Button Click");
  };

  return (
    <MainContainer bg="transparent">
      <Flex
        alignItems={{ base: "flex-start", md: "flex-end" }}
        justifyContent="space-between"
        flexDir={{ base: "column", md: "row" }}
        gap="24px"
        py="24px"
      >
        <Flex
          flexDir={{ base: "column", sm: "row" }}
          gap={{ base: "24px", sm: "12px" }}
        >
          <Heading fontSize={14} color={"brand.gray"}>
            © 2024 Hudi, Inc. All rights reserved.
          </Heading>

          <HStack>
            <Heading
              fontSize={14}
              color={"brand.gray"}
              onClick={() => {
                tag("W_PRIVACY_CLICK");
                navigate("/privacy");
              }}
              cursor={"pointer"}
              _hover={{
                textDecoration: "underline",
                textUnderlineOffset: "3px",
              }}
            >
              Privacy
            </Heading>

            <Heading fontSize={14} color={"brand.gray"}>
              |
            </Heading>

            <Heading
              fontSize={14}
              color={"brand.gray"}
              onClick={() => {
                tag("W_TERMS_CLICK");
                navigate("/terms");
              }}
              cursor={"pointer"}
              _hover={{
                textDecoration: "underline",
                textUnderlineOffset: "3px",
              }}
            >
              Terms
            </Heading>
          </HStack>
        </Flex>

        <HStack>
          <Heading
            fontSize={14}
            color={"brand.gray"}
            onClick={() => {
              tag("W_IG_CLICK");
              window.open("https://www.instagram.com/gethudi", "_blank");
            }}
            cursor={"pointer"}
            _hover={{
              textDecoration: "underline",
              textUnderlineOffset: "3px",
            }}
          >
            Instagram
          </Heading>

          <Heading fontSize={14} color={"brand.gray"}>
            |
          </Heading>

          <Heading
            fontSize={14}
            color={"brand.gray"}
            onClick={() => {
              tag("W_TWITTER_CLICK");
              window.open("https://x.com/gethudi", "_blank");
            }}
            cursor={"pointer"}
            _hover={{
              textDecoration: "underline",
              textUnderlineOffset: "3px",
            }}
          >
            X (Twitter)
          </Heading>

          <Heading fontSize={14} color={"brand.gray"}>
            |
          </Heading>

          <Heading
            fontSize={14}
            color={"brand.gray"}
            onClick={() => {
              tag("W_TIKTOK_CLICK");
              window.open("https://tiktok.com/@gethudi", "_blank");
            }}
            cursor={"pointer"}
            _hover={{
              textDecoration: "underline",
              textUnderlineOffset: "3px",
            }}
          >
            Tiktok
          </Heading>
        </HStack>
      </Flex>
    </MainContainer>
  );
};
