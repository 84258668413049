import {
  Box,
  Heading,
  Text,
  Image,
  Grid,
  GridItem,
  Flex,
} from "@chakra-ui/react";

import { MainContainer } from "../../layout";

import NotificationsImg from "../../assets/images/notifications.png";

export const Notifications = () => {
  const calculatedHeight3 = `calc(50vh - 5rem - 38px)`;
  const calculatedHeight4 = `calc(60vh - 5rem - 38px)`;

  return (
    <Box>
      <MainContainer bg="brand.white">
        <Grid
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          gap={"32px"}
          pt={{ base: "60px", lg: "0px" }}
        >
          <GridItem display={{ base: "none", lg: "block" }}>
            <Flex
              align="center"
              justify="center"
              h={{ base: "auto", lg: "596px" }}
              w={{ base: "70%", lg: "auto" }}
              overflow={"hidden"}
            >
              <Image
                src={NotificationsImg}
                alt="phone"
                objectFit={"cover"}
                h={"140%"}
              />
            </Flex>
          </GridItem>

          <GridItem
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            px={{ base: 0, lg: "5vw" }}
          >
            <Heading
              fontSize={{ base: "32px", lg: "60px" }}
              color="brand.black"
              mb="10px"
            >
              Receive daily coaching notifications
            </Heading>

            <Text fontSize={{ base: "1rem", xl: "1.2rem" }} color="brand.gray">
              Receive powerful reminders, tips, suggestions, and nudges to give
              you the motivation you need to keep making progress towards your
              habit.
            </Text>
          </GridItem>

          <GridItem display={{ base: "block", lg: "none" }}>
            <Flex
              align={{ base: "flex-start", lg: "center" }}
              justify="center"
              h={{ base: calculatedHeight4, sm: "620px" }}
              w={"auto"}
              overflow={'hidden'}
            >
              <Image
                src={NotificationsImg}
                alt="phone"
                objectFit={"cover"}
                h={{ base: "140%", sm: "140%" }}
                w={"auto"}
              />
            </Flex>
          </GridItem>
        </Grid>
      </MainContainer>
    </Box>
  );
};
