import React from "react";
import { Box, Container } from "@chakra-ui/react";

interface MainContainerProps extends React.PropsWithChildren {
  bg?: string;
}

export const MainContainer = ({ bg, children }: MainContainerProps) => {
  return (
    <Box bg={bg || "transparent"} position="relative">
      <Container
        px={{ base: "5%", xl: "6%", "2xl": "12.5%" }}
        maxW={["100%"]}
      >
        {children}
      </Container>
    </Box>
  );
};
