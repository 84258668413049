import { useState, useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Image,
  HStack,
  Grid,
  GridItem,
  Flex,
  Icon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";
import { TypeAnimation } from "react-type-animation";
// import { useNavigate } from "react-router-dom";

import { mixPanelTag } from "../../queries";
import { MainContainer } from "../../layout";

import AppStore from "../../assets/images/appStore.png";
import PlayStore from "../../assets/images/playStore.png";
import HeroPhone from "../../assets/images/heroPhone.png";

const coloredWords = [
  { color: "#56DC8D", word: "Jannah" },
  { color: "#8959F4", word: "Allah" },
  { color: "#FFCC02", word: "Success" },
];

export const Hero = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [color, setColor] = useState<number>(0);
  const [forceUpdate, setForceUpdate] = useState<number>(0);

  const tagDownloadButton = async (os: "ios" | "android") => {
    mixPanelTag(
      os === "ios" ? "W_IOS_DOWNLOAD" : "W_ANDROID_DOWNLOAD",
      "Button Click"
    );
  };

  useEffect(() => {
    const interval = setTimeout(() => {
      if (color < 2) {
        setColor((prev) => prev + 1);
      } else setColor(0);
    }, 3000);

    return () => clearInterval(interval);
  }, [color]);

  useEffect(() => {
    setForceUpdate((prev) => prev + 1);
  }, [color]);

  // const navigate = useNavigate();

  const calculatedHeight = `calc(100vh - 5rem)`;
  const calculatedHeight2 = `calc(100vh - 5rem - 38px)`;
  const calculatedHeight3 = `calc(50vh - 5rem - 38px)`;
  const calculatedHeight4 = `calc(60vh - 5rem - 38px)`;

  return (
    <Box>
      <MainContainer bg="brand.black">
        <Grid
          gap={{ base: "3rem", md: "4rem" }}
          gridTemplateColumns={{ base: "1fr", xl: "1fr 1fr" }}
          alignItems="center"
          pt="38px"
          h={{ base: "auto", xl: calculatedHeight }}
        >
          <GridItem pr={{ base: 0, sm: "5vw" }}>
            <Heading
              fontSize={{ base: "2.5rem", xl: "4.5rem" }}
              color="brand.white"
              pr={{ base: 0, sm: "3vw" }}
            >
              Build habits that lead to{" "}
              <Box display={{ base: "none", xl: "block" }} />
              <TypeAnimation
                sequence={[
                  coloredWords[color].word,
                  2000,
                  coloredWords[color < 2 ? color + 1 : 0].word,
                ]}
                wrapper="span"
                speed={1}
                style={{ color: coloredWords[color].color }}
                repeat={Infinity}
                key={forceUpdate}
              />
            </Heading>

            <Text
              color="brand.white"
              fontSize={{ base: "1rem", xl: "1.25rem" }}
              mt="1rem"
            >
              Hudi helps you build daily spiritual habits as a Muslim, starting
              with your daily salah.
            </Text>

            <HStack mt={{ base: "1.5rem", xl: "2.5rem" }} spacing="24px">
              <a
                href={`https://apps.apple.com/ng/app/hudi-build-jannah-habits/id6469621563`}
                target="_blank"
                rel="noreferrer"
                onClick={() => tagDownloadButton("ios")}
              >
                <Image src={AppStore} alt="" h={{ base: "40px", xl: "52px" }} />
              </a>

              <a
                href={`https://play.google.com/store/apps/details?id=com.gethudi.mobile`}
                target="_blank"
                rel="noreferrer"
                onClick={() => tagDownloadButton("android")}
              >
                <Image
                  src={PlayStore}
                  alt=""
                  h={{ base: "40px", xl: "52px" }}
                />
              </a>
            </HStack>
          </GridItem>

          <GridItem>
            <Flex
              align="center"
              justify={{ base: "center", xl: "flex-end" }}
              pos={"relative"}
            >
              <Flex
                pt={{ base: 0, xl: "6vh" }}
                h={{ base: calculatedHeight4, sm: calculatedHeight2 }}
                w={"auto"}
                overflow={"hidden"}
              >
                <Image
                  src={HeroPhone}
                  alt="phone"
                  objectFit={"cover"}
                  h={{ base: "140%", sm: "110%" }}
                  paddingRight={{ base: "0px", sm: "27px" }}
                />

                <Flex
                  pos={"fixed"}
                  zIndex={2}
                  bottom={"24px"}
                  right={{ base: "5%", xl: "6%", "2xl": "12.5%" }}
                  w={"54px"}
                  h={"54px"}
                  backgroundColor={"brand.green.400"}
                  borderRadius={"32px"}
                  align={"center"}
                  justify={"center"}
                  cursor={"pointer"}
                  _hover={{ w: "64px", h: "64px" }}
                  transition={"ease-in 0.2s"}
                  onClick={onOpen}
                >
                  <Icon
                    width="23"
                    height="23"
                    viewBox="0 0 26 21"
                    fill={"none"}
                  >
                    <path
                      d="M16.25 7.5C17.6997 7.5 18.875 6.32475 18.875 4.875C18.875 3.42525 17.6997 2.25 16.25 2.25C14.8003 2.25 13.625 3.42525 13.625 4.875C13.625 6.32475 14.8003 7.5 16.25 7.5Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5.75 13.625C7.19975 13.625 8.375 12.4497 8.375 11C8.375 9.55025 7.19975 8.375 5.75 8.375C4.30025 8.375 3.125 9.55025 3.125 11C3.125 12.4497 4.30025 13.625 5.75 13.625Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.25 19.75C17.6997 19.75 18.875 18.5747 18.875 17.125C18.875 15.6753 17.6997 14.5 16.25 14.5C14.8003 14.5 13.625 15.6753 13.625 17.125C13.625 18.5747 14.8003 19.75 16.25 19.75Z"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.0166 12.3213L13.9929 15.8038"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M13.9841 6.19629L8.0166 9.67879"
                      stroke="white"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </Icon>
                </Flex>
              </Flex>
            </Flex>
          </GridItem>
        </Grid>

        <Modal
          isCentered
          closeOnOverlayClick={false}
          onClose={onClose}
          isOpen={isOpen}
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent borderRadius={"25px"}>
            <ModalCloseButton />
            <ModalBody pt="48px" pb="36px">
              <Text
                fontSize={"1.25rem"}
                color={"brand.gray"}
                textAlign={"center"}
              >
                Share with your friends
              </Text>

              <Flex flexDir={"column"} gap={"16px"} mt="16px">
                <Button
                  bg="linear-gradient(264.12deg, #1da1f2 39.02%, #00dbc2 116.51%)"
                  _hover={{
                    bg: "linear-gradient(264.12deg, #1da1f2 39.02%, #00dbc2 116.51%)",
                  }}
                  color={"brand.white"}
                  onClick={() =>
                    window.open(
                      "https://x.com/intent/post?text=Hudi+helps+you+build+daily+spiritual+habits+as+a+Muslim%2C+starting+with+your+daily+salah.&url=https%3A%2F%2Fgethudi.com",
                      "_blank"
                    )
                  }
                >
                  Share on X (Twitter)
                </Button>

                <Button
                  bg="linear-gradient(264.12deg,#4a8bee 10%,#1678f2 116.51%)"
                  _hover={{
                    bg: "linear-gradient(264.12deg,#4a8bee 10%,#1678f2 116.51%)",
                  }}
                  color={"brand.white"}
                  onClick={() =>
                    window.open(
                      "https://web.facebook.com/gethudi?_rdc=1&_rdr",
                      "_blank"
                    )
                  }
                >
                  Share on Facebook
                </Button>

                <Button
                  bg="linear-gradient(264.12deg,#25d366 39.02%,#128c7e 116.51%)"
                  _hover={{
                    bg: "linear-gradient(264.12deg,#25d366 39.02%,#128c7e 116.51%)",
                  }}
                  color={"brand.white"}
                  onClick={() =>
                    window.open(
                      "https://api.whatsapp.com/send/?phone&text=Hudi+helps+you+build+daily+spiritual+habits+as+a+Muslim%2C+starting+with+your+daily+salah.+https%3A%2F%2Fgethudi.com&app_absent=0",
                      "_blank"
                    )
                  }
                >
                  Share on Whatsapp
                </Button>
              </Flex>
            </ModalBody>
          </ModalContent>
        </Modal>
      </MainContainer>
    </Box>
  );
};
