import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { mixPanelTag } from "../queries";
import { MainLayout } from "../layout";
import { Download } from "../features/home";
import { Content } from "../features/terms";

export const Terms = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
    mixPanelTag("W_TERMS", "Page Visit");
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hudi | Terms of Use</title>
        <meta name="description" content={"Hudi Terms of Use"} />
      </Helmet>

      <MainLayout bg="brand.white">
        <Content />
        <Download />
      </MainLayout>
    </div>
  );
};
