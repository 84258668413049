import { Fragment, PropsWithChildren } from "react";

import { Footer, Header } from "../components";

export const MainLayout = ({ children, bg }: PropsWithChildren | any) => {
  return (
    <Fragment>
      <Header bg={bg} />
      {children}
      <Footer />
    </Fragment>
  );
};
