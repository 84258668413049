import { useEffect } from "react";
import { Helmet } from "react-helmet";

import { mixPanelTag } from "../queries";
import {
  // Accountability,
  // Challenges,
  Commit,
  Companion,
  Download,
  Hero,
  Notifications,
  TrackProgress,
} from "../features/home";
import { MainLayout } from "../layout";

export const Home = () => {
  useEffect(() => {
    mixPanelTag("W_LANDINGPAGE_VISIT", "Page Visit");
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hudi | Build habits that lead to Jannah</title>
        <meta name="description" content={"Hudi Home Page"} />
      </Helmet>

      <MainLayout bg="brand.black">
        <Hero />
        <Companion />
        <Commit />
        <Notifications />
        <TrackProgress />
        {/* <Challenges />
        <Accountability /> */}
        <Download />
      </MainLayout>
    </div>
  );
};
