import { useEffect, useState, useRef } from "react";
import { Box, Heading, Text, Grid, GridItem, Link } from "@chakra-ui/react";
import { ListItem, UnorderedList, OrderedList } from "@chakra-ui/react";

import { MainContainer } from "../../layout";

const textStyle = {
  fontSize: "1.2rem",
  color: "brand.gray",
  fontWeight: 500,
  "@media only screen and (max-width: 992px)": {
    fontSize: "1rem", // Adjust font size for smaller screens
  },
};

const headerStyle = {
  fontSize: "2rem",
  color: "brand.black",
  "@media only screen and (max-width: 992px)": {
    fontSize: "1.5rem", // Adjust font size for smaller screens
  },
};

const scrollbarStyles = {
  /* Enable scrollbar appearance */
  "&::-webkit-scrollbar": {
    width: "5px", // Customize width
  },
  /* Scrollbar track styles */
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent", // Customize background color
  },
  /* Scrollbar thumb styles */
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#eee", // Customize thumb color
    borderRadius: "6px", // Customize corner radius
  },
};

const headers = [
  {
    id: 1,
    title: "Our Services",
  },
  {
    id: 2,
    title: "General Account Terms",
  },
  {
    id: 3,
    title: "Communication",
  },
  {
    id: 4,
    title: "Content",
  },
  {
    id: 5,
    title: "Our Fees",
  },
  {
    id: 6,
    title: "Copyright Policy",
  },
  {
    id: 7,
    title: "HUDI Intellectual Property Ownership",
  },
  {
    id: 8,
    title: "Changes",
  },
  {
    id: 9,
    title: "Data",
  },
  {
    id: 10,
    title: "Disclaimer of Warranties",
  },
  {
    id: 11,
    title: "Limitation of Liability",
  },
  {
    id: 12,
    title: "Indemnification",
  },
  {
    id: 13,
    title: "Disputes Resolution",
  },
  {
    id: 14,
    title: "Miscellaneous",
  },
  {
    id: 15,
    title: "Contacting HUDI",
  },
];

export const Content = () => {
  const [activeHeader, setActiveHeader] = useState<number>(0);
  const headerRefs = useRef<any>([]); // Array to store header element refs

  const handleScroll = () => {
    const activeHeaderId = headerRefs.current.find((el: any) => {
      const rect = el?.getBoundingClientRect();
      const isInView = rect?.top >= 0 && rect?.top <= window.innerHeight;
      return isInView;
    })?.id;

    if (activeHeaderId !== activeHeader) {
      setActiveHeader(activeHeaderId);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <Box py="40px">
      <MainContainer bg="brand.white">
        <Grid
          gridTemplateColumns={{ base: "1fr", xl: "5fr 13fr 2fr" }}
          gap={"48px"}
        >
          <GridItem
            display={{ base: "none", xl: "block" }}
            pt="36px"
            fontSize={"0.85rem"}
          >
            <Box
              w="100%"
              h="400px"
              pt="12px"
              overflowY={"scroll"}
              px="6px"
              pos={"sticky"}
              top="0"
              left="0"
              sx={scrollbarStyles}
            >
              <Text color="brand.gray">TABLE OF CONTENTS</Text>

              <OrderedList spacing={"16px"} mt="16px">
                {headers.map((header) => (
                  <ListItem key={header.id} className={String(header.id)}>
                    <Link
                      href={`#${header.id}`}
                      fontWeight={
                        Number(activeHeader) === header.id ? "bold" : "normal"
                      }
                    >
                      {header.title}
                    </Link>
                  </ListItem>
                ))}
              </OrderedList>
            </Box>
          </GridItem>

          <GridItem alignContent={"center"}>
            <Box
              borderBottomWidth={"1px"}
              borderColor={"brand.lightgray"}
              mb={{ base: "12px", lg: "40px" }}
            >
              <Heading
                fontSize={{ base: "2.25rem", lg: "60px" }}
                color="brand.black"
                mb={{ lg: "10px" }}
              >
                Terms of Use
              </Heading>

              <Heading sx={textStyle} mb="48px">
                This terms of use was last updated on August 30, 2021.
              </Heading>
            </Box>

            <Box>
              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="1">
                <Heading sx={headerStyle}>1. Our Services</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  The following terms and conditions govern use of all content,
                  services, and products (the “Service”) provided by HUDI, INC.
                  (“HUDI”) and available on the HUDI site (the “Site”). The
                  Service is owned and operated by HUDI. The Site is intended to
                  be used by Account Owners (“Account Owner(s)”) and Account
                  Users (“Account User(s)” together with Account Owner “User” or
                  “Users”) in connection with the Services.
                </Text>

                <Text sx={textStyle} my="20px">
                  The Service is offered subject to your acceptance without
                  modification of all of the terms and conditions contained
                  herein and all other operating rules, policies, and procedures
                  that may be published from time to time on the Site
                  (collectively, the “Agreement”). If you are entering into this
                  agreement on behalf of a company or other legal entity, you
                  represent that you have the authority to bind such entity, its
                  affiliates, and all users who access the Service through your
                  account to this Agreement, in which case the terms “you” or
                  “your” shall refer to such entity, its affiliates, and users
                  associated with it. If you do not have such authority, or if
                  you do not agree with these terms and conditions, you must not
                  accept this Agreement and may not use the Service.
                </Text>

                <Heading
                  sx={textStyle}
                  fontFamily={"avertape-semibold"}
                  my="32px"
                >
                  PLEASE READ THIS AGREEMENT CAREFULLY BEFORE ACCESSING OUR SITE
                  OR USING THE SERVICE. BY ACCESSING OR USING ANY PART OF THE
                  SITE, YOU AGREE TO BECOME BOUND BY THIS AGREEMENT. THESE TERMS
                  INCLUDE A BINDING ARBITRATION CLAUSE AND A CLASS ACTION
                  WAIVER.
                </Heading>

                <Text sx={textStyle} my="20px">
                  If you do not agree to all the terms and conditions of this
                  Agreement, then you may not access the Service. If these terms
                  and conditions are considered an offer by HUDI, acceptance is
                  expressly limited to these terms
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="2">
                <Heading sx={headerStyle}>2. General Account Terms</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  You must be a human. Accounts registered by “bots” or other
                  automated methods are not permitted.
                </Text>

                <Text sx={textStyle} mb="32px">
                  Each account may be only used by one person and you may only
                  have one account. If it is discovered that you have registered
                  multiple accounts, are sharing accounts with another
                  individual, or are otherwise accessing the Site through an
                  account that is not your own, all accounts affected by such
                  use shall be terminated.
                </Text>

                <Text sx={textStyle} mb="32px">
                  You are responsible for maintaining the security of your
                  account and password. HUDI cannot and will not be liable for
                  any loss or damage from your failure to comply with this
                  security obligation. You are fully responsible for all
                  activities that occur under the account and any other actions
                  taken in connection with the account. You must immediately
                  notify HUDI of any unauthorized uses of your account, or any
                  other breaches of security. HUDI will not be liable for any
                  acts or omissions by you, including any damages of any kind
                  incurred as a result of such acts or omissions.
                </Text>

                <Text sx={textStyle} mb="32px">
                  You may not use the Service for any illegal or unauthorized
                  purpose. You must not, in the use of the Service, violate any
                  laws in your jurisdiction (including but not limited to
                  copyright or trademark laws). No matter what else is said in
                  these Terms or anywhere else within the Services, you
                  expressly acknowledge that you have no right, title, or
                  interest to or in any Account you create through our Services,
                  and your Account is not your property.
                </Text>

                <Text sx={textStyle} mb="32px">
                  By using the Site, you warrant and represent that you are at
                  least 18 years of age and otherwise legally competent to read,
                  understand and accept the provisions of this Agreement. If you
                  are under 18 years of age, you must not use any part of the
                  Site, create an account, or submit any personal information to
                  HUDI through the Site.
                </Text>

                <Text sx={textStyle} mb="32px">
                  HUDI may remove Content that violates the Terms at its sole
                  discretion. Removing Content, or terminating an account are
                  not actions HUDI take lightly, and we may take a while to
                  investigate and determine if such actions are warranted. It is
                  at HUDI’s sole discretion whether to discuss the reasons for
                  taking action against any Content or account.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="3">
                <Heading sx={headerStyle}>3. Communication</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  By using this Site and our services you give HUDI your express
                  permission to contact you via email, messaging, or other
                  electronic or non-electronic forms of communication for all
                  purposes including distribution of marketing and promotional
                  materials.
                </Text>

                <Text sx={textStyle} my="32px">
                  We provide interactive services on our Site, including,
                  without limitation chat rooms and bulletin boards. We will
                  take reasonable endeavours to assess any possible risks for
                  Users from third parties when they use any interactive service
                  provided on our Site, and we will decide in each case whether
                  it is appropriate to use moderation of the relevant Service
                  (including what kind of moderation to use) in the light of
                  those risks. However, we are under no obligation to oversee,
                  monitor or moderate any interactive service we provide on our
                  Site, and we expressly exclude our liability for any loss or
                  damage arising from the use of any interactive service by a
                  user in contravention of our content standards, whether the
                  Service is moderated or not. Should a difficulty arise, the
                  user should contact us by emailing support@gethudi.com
                  immediately.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="4">
                <Heading sx={headerStyle}>4. Content</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  If you make (or allow any third party to make) material
                  available through the Site (any such material, “Content”), you
                  are entirely responsible for the content of, and any harm
                  resulting from, that Content. That is the case regardless of
                  whether the Content in question constitutes text, graphics, an
                  audio file, or computer software.
                </Text>

                <Heading fontSize={"1.2rem"} color="brand.gray" my="32px">
                  A. By making Content available, you represent and warrant
                  that:
                </Heading>

                <UnorderedList spacing={"20px"} my="20px" pl="30px">
                  <ListItem>
                    <Text sx={textStyle}>
                      the downloading, copying, and use of the Content will not
                      infringe the proprietary rights, including but not limited
                      to the copyright, patent, trademark, or trade secret
                      rights, of any third party;
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      if your employer has rights to intellectual property you
                      create, you have either (i) received permission from your
                      employer to post or make available the Content, including
                      but not limited to any software, or (ii) secured from your
                      employer a waiver as to all rights in or to the Content;
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      you have fully complied with any third-party licenses
                      relating to the Content, and have done all things
                      necessary to successfully pass through to end users any
                      required terms;
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      the Content does not contain or install any viruses,
                      worms, malware, trojan horses, or other harmful or
                      destructive content;
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      the Content is not spam, is not machine- or
                      randomly-generated, and does not contain unethical or
                      unwanted commercial content designed to drive traffic to
                      third party sites or boost the search engine rankings of
                      third party sites, or to further unlawful acts (such as
                      phishing) or mislead recipients as to the source of the
                      material (such as spoofing);
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      the Content is not pornographic, does not contain threats
                      or incite violence, and does not violate the privacy or
                      publicity rights of any third party;
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      the Content is not getting advertised via unwanted
                      electronic messages such as spam links on newsgroups,
                      email lists, other groups and web sites, and similar
                      unsolicited promotional methods;
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      the Content is not named in a manner that misleads your
                      readers into thinking that you are another person or
                      company; and
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      you have, in the case of Content that includes computer
                      code, accurately categorized and/or described the type,
                      nature, uses, and effects of the materials, whether
                      requested to do so by HUDI or otherwise.
                    </Text>
                  </ListItem>
                </UnorderedList>

                <Heading fontSize={"1.2rem"} color="brand.gray" my="32px">
                  B. Without limiting any of those representations or
                  warranties, HUDI has the right (though not the obligation) to,
                  in HUDI’ s sole discretion:
                </Heading>

                <UnorderedList spacing={"20px"} my="20px" pl="30px">
                  <ListItem>
                    <Text sx={textStyle}>
                      refuse or remove any content that, in HUDI’ s reasonable
                      opinion, violates any HUDI policy or is in any way harmful
                      or objectionable, or
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      terminate or deny access to and use of the Service to any
                      individual or entity for any reason. HUDI will have no
                      obligation to provide a refund of any amounts previously
                      paid.
                    </Text>
                  </ListItem>
                </UnorderedList>

                <Text sx={textStyle} my="32px">
                  As a user, you may deactivate your account at any time.
                  Personally identifying information such as your name,
                  username, and profile photo will no longer be associated with
                  Content you have posted.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="5">
                <Heading sx={headerStyle}>5. Our Fees</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  You agree to pay HUDI the fees indicated for the Service.
                  Unless you notify HUDI thirty (30) days before the end of the
                  applicable period that you want to cancel a subscription, your
                  subscription will automatically renew and you authorize us to
                  collect the then-applicable fee for such subscription (as well
                  as any taxes) using any credit card or other payment mechanism
                  we have on record for you.
                </Text>

                <Box my="32px">
                  <Heading fontSize={"1.2rem"} color="brand.gray" mb="6px">
                    Cancellation Policy
                  </Heading>

                  <Text sx={textStyle}>
                    All of the fees are not non-refundable.
                  </Text>
                </Box>

                <Box my="32px">
                  <Heading fontSize={"1.2rem"} color="brand.gray" mb="6px">
                    Refunds
                  </Heading>

                  <Text sx={textStyle}>
                    You may cancel your subscription anytime by logging in to
                    your HUDI account page.
                  </Text>
                </Box>

                <Text sx={textStyle} my="32px">
                  In order to use certain Site Services, you must provide
                  account information for at least one valid payment method.
                </Text>

                <Text sx={textStyle} my="32px">
                  You hereby authorize HUDI, to run credit card authorizations
                  on all credit cards provided by you, to store credit card and
                  banking or other financial details as your method of payment
                  consistent with our Privacy Statement, and to charge your
                  credit card (or any other payment method) for the Fees and any
                  other amounts owed under the Terms of Service. To the extent
                  permitted by applicable law and subject to our Privacy Policy,
                  you acknowledge and agree that we may use certain third-party
                  vendors and service providers to process payments and manage
                  your payment method information.
                </Text>

                <Text sx={textStyle} my="32px">
                  By providing payment method information through the Site and
                  authorizing payments with the payment method, you represents,
                  warrants, and covenants that: (a) you are legally authorized
                  to provide such information; (b) you are legally authorized to
                  make payments using the payment method(s); (c) if you are an
                  employee or agent of a company or person that owns the payment
                  method, you are authorized by the company or person to use the
                  payment method to make payments on BOTNOT; and (d) such
                  actions do not violate the terms and conditions applicable to
                  your use of such payment method(s) or applicable law.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="6">
                <Heading sx={headerStyle}>6. Copyright Policy</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  HUDI respects copyright law and expects its users to do the
                  same. It is HUDI’s policy to terminate in appropriate
                  circumstances users or other account holders who repeatedly
                  infringe or are believed to be repeatedly infringing the
                  rights of copyright holders.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="7">
                <Heading sx={headerStyle}>
                  7. HUDI Intellectual Property Ownership
                </Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  The Services contain proprietary materials such as logos,
                  text, software, photos, video, graphics, music and sound. HUDI
                  is the owner or licensee of all such proprietary material and
                  all intellectual property rights therein, including without
                  limitation, copyrights and trademarks. In using the Services,
                  you agree not to upload, download, distribute, or reproduce in
                  any way any such proprietary materials without obtaining HUDI’
                  prior consent.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="8">
                <Heading sx={headerStyle}>8. Changes</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  We reserve the right to modify these terms at any time and
                  modified terms are effective upon posting to the Site. We will
                  make reasonable efforts to inform you of these changes via
                  email or through the Service. Your continued use of or access
                  to the Service following the posting of any changes to this
                  Agreement constitutes acceptance of those changes. HUDI may
                  also, in the future, offer new services and/or features
                  including the release of new tools and resources. Such new
                  features and/or services shall be subject to the terms and
                  conditions of this Agreement.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="9">
                <Heading sx={headerStyle}>9. Data</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  Users may request data related to their personal account and
                  group(s) respectively. HUDI has the right to refuse repetitive
                  or technically difficult requests. For information about how
                  we collect and share user information please refer to our
                  <Link fontWeight={800} mx="5px" textDecoration={"underline"}>
                    Customer
                  </Link>
                  and
                  <Link fontWeight={800} mx="5px" textDecoration={"underline"}>
                    Child
                  </Link>
                  Privacy Statement.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="10">
                <Heading sx={headerStyle}>10. Disclaimer of Warranties</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  THE SERVICE IS PROVIDED “AS IS” AND "AS AVAILABLE". HUDI AND
                  ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, SUBSIDIARIES,
                  AFFILIATES, LICENSORS AND SUPPLIERS HEREBY EXPRESSLY DISCLAIM
                  ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING,
                  WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF TITLE,
                  MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR A
                  PARTICULAR PURPOSE. NEITHER HUDI NOR ITS OFFICERS, DIRECTORS,
                  EMPLOYEES, AGENTS, SUBSIDIARIES, AFFILIATES, LICENSORS NOR
                  SUPPLIERS WARRANT THAT THE OPERATION OF THE SERVICE WILL BE
                  UNINTERRUPTED, ERROR FREE OR WITHOUT BREACHES OF SECURITY.
                </Text>

                <Text sx={textStyle} my="32px">
                  HUDI, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
                  SUBSIDIARIES, AFFILIATES, AND ITS LICENSORS DO NOT REPRESENT
                  OR WARRANT THAT:
                </Text>

                <UnorderedList spacing={"20px"} my="20px" pl="30px">
                  <ListItem>
                    <Text sx={textStyle}>
                      THE INFORMATION PROVIDED ON SITE IS TRUE OR CORRECT,
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      YOUR USE OF THE SERVICES WILL MEET YOUR REQUIREMENTS OR
                      EXPECTATIONS,
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      YOUR USE OF THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
                      SECURE OR FREE FROM ERROR,
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      ANY INFORMATION OBTAINED BY YOU AS A RESULT OF YOUR USE OF
                      THE SERVICES WILL BE ACCURATE OR RELIABLE, OR
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      THAT DEFECTS IN THE OPERATION OR FUNCTIONALITY OF ANY
                      SOFTWARE PROVIDED TO YOU AS PART OF THE SERVICES WILL BE
                      CORRECTED.
                    </Text>
                  </ListItem>
                </UnorderedList>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="11">
                <Heading sx={headerStyle}>11. Limitation of Liability</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  IN NO EVENT WILL HUDI ITS OFFICERS, DIRECTORS, EMPLOYEES,
                  AGENTS, SUBSIDIARIES OR AFFILIATES BE LIABLE TO ANY PARTY FOR:
                </Text>

                <Heading
                  sx={textStyle}
                  fontFamily={"avertape-semibold"}
                  my="32px"
                >
                  A. ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
                  EXEMPLARY, OR PUNITIVE, DAMAGES ARISING OUT OF THE USE,
                  AVAILABILITY OR UNAVAILABILITY OF THE SERVICES (INCLUDING BUT
                  NOT LIMITED TO THE UNAVAILABILITY OR CHANGE IN ADVERTISED
                  SERVICES) OR ANY OTHER SERVICES OR GOODS OBTAINED THROUGH USE
                  OF ANY OF THE FOREGOING, OR ANY DATA TRANSMITTED THROUGH THE
                  SERVICE, EVEN IF HUDI HAS BEEN ADVISED OF THE POSSIBILITY OF
                  SUCH DAMAGES; AND
                </Heading>

                <UnorderedList spacing={"20px"} my="20px" pl="30px">
                  <ListItem>
                    <Text sx={textStyle}>
                      ANY LOSS OR DAMAGE WHICH MAY BE INCURRED BY YOU, INCLUDING
                      BUT NOT LIMITED TO LOSS OR DAMAGE AS A RESULT OF:
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      ANY RELIANCE PLACED BY YOU ON THE COMPLETENESS, ACCURACY
                      OR EXISTENCE OF ANY ADVERTISING, OR AS A RESULT OF ANY
                      RELATIONSHIP OR TRANSACTION BETWEEN YOU AND ANY ADVERTISER
                      OR SPONSOR WHOSE ADVERTISING APPEARS ON THE SERVICES;
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      ANY CHANGES WHICH HUDI MAY MAKE TO THE SERVICES, OR FOR
                      ANY PERMANENT OR TEMPORARY CESSATION IN THE PROVISION OF
                      THE SERVICES (OR ANY FEATURES WITHIN THE SERVICES);
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      YOUR FAILURE TO PROVIDE HUDI WITH ACCURATE INFORMATION;
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      YOUR FAILURE TO KEEP YOUR PASSWORD OR ACCOUNT DETAILS
                      SECURE AND CONFIDENTIAL.
                    </Text>
                  </ListItem>
                </UnorderedList>

                <Heading
                  sx={textStyle}
                  fontFamily={"avertape-semibold"}
                  my="32px"
                >
                  B. ANY BODILY INJURY, DEATH, OR PROPERTY DAMAGE THAT MAY OCCUR
                  AS THE RESULT OF SERVICES.
                </Heading>

                <Heading
                  sx={textStyle}
                  fontFamily={"avertape-semibold"}
                  my="32px"
                >
                  C. NOTWITHSTANDING THE TERMS, IN NO EVENT SHALL HUDI BE HELD
                  LIABLE, FOR ANY AMOUNTS IN EXCESS OF (IN THE AGGREGATE) ONE
                  HUNDRED U.S. DOLLARS ($100.00). HUDI SHALL HAVE NO LIABILITY
                  FOR ANY FAILURE OR DELAY DUE TO MATTERS BEYOND THEIR
                  REASONABLE CONTROL. THE FOREGOING SHALL NOT APPLY TO THE
                  EXTENT PROHIBITED BY APPLICABLE LAW.
                </Heading>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="12">
                <Heading sx={headerStyle}>12. Indemnification</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  You agree to indemnify, release, defend and hold harmless HUDI
                  and its directors, officers, agents and employees
                  (collectively, the “HUDI parties”) from any claims, causes of
                  action, demands, recoveries, losses, damages (actual and
                  consequential), fines, penalties and other costs or expenses,
                  of any kind or nature, including but not limited to reasonable
                  legal and accounting fees (collectively, the “claims”), known
                  and unknown, arising out of or in any way connected with such
                  disputes, including, without limitation, disputes arising over
                  breach of this agreement, breach of any separate agreement or
                  transaction, and/or the substitution of units. Further, you
                  hereby agree to indemnify, release, defend and hold harmless
                  the HUDI parties from any claims brought as a result of your
                  (1) breach of the terms, (2) violation of any law or the
                  rights of a third party, and (3) use of this Site and the
                  Services.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="13">
                <Heading sx={headerStyle}>13. Disputes Resolution</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  This Agreement constitutes the entire agreement between HUDI
                  and you concerning the subject matter hereof, and they may
                  only be modified by a written amendment signed by an
                  authorized agent/employee of HUDI, or by the posting by HUDI
                  of a revised version. Any and all disputes arising of this
                  Agreement, termination, or our relationship with you shall be
                  determined by applying laws of the State of Delaware.
                </Text>

                <Text sx={textStyle} my="32px">
                  If any part of this Agreement is held invalid or
                  unenforceable, that part will be struck, and the remaining
                  portions will remain in full force and effect. A waiver by
                  either party of any term or condition of this Agreement or any
                  breach thereof, in any one instance, will not waive such term
                  or condition or any subsequent breach thereof. You may not
                  assign your rights under this Agreement without written
                  consent from HUDI; HUDI may assign its rights under this
                  Agreement without condition. This Agreement will be binding
                  upon and will inure to the benefit of the parties, their
                  successors and permitted assigns.
                </Text>

                <Heading
                  sx={textStyle}
                  fontFamily={"avertape-semibold"}
                  my="32px"
                >
                  ARBITRATION
                </Heading>

                <Text sx={textStyle} my="32px">
                  Dispute resolution and arbitration
                </Text>

                <Text sx={textStyle} my="32px">
                  By using this Site, you agree that any and all disputes,
                  claims or controversies that you may have against HUDI arising
                  out of or relating to or connected in any way to (i) your use
                  of this Site (ii) any services obtained from or provided by
                  HUDI or (iii) this Agreement (including the interpretation and
                  scope of this clause and the arbitrability of the dispute),
                  shall be resolved exclusively by mandatory, binding
                  arbitration initiated through and administered by the American
                  Arbitration Association ("AAA"). You further agree that
                  arbitration will be conducted by a single arbitrator pursuant
                  to the applicable Rules and Procedures established by AAA, and
                  that any arbitration proceeding, if necessary, will be held in
                  Delaware or at such other location as may be mutually agreed
                  upon by HUDI and you. Arbitration will be subject to the
                  Federal Arbitration Act and not any state arbitration law. The
                  arbitrator shall apply Delaware law consistent with the
                  Federal Arbitration Act, and shall honor claims of privilege
                  recognized at law. In order to initiate arbitration with the
                  AAA, you may be responsible for paying a filing fee to the
                  AAA, however, in the event you are able to demonstrate that
                  the costs of arbitration will be prohibitive as compared to
                  the costs of litigation, HUDI will reimburse as much of your
                  filing and hearing fees in connection with the arbitration as
                  the arbitrator deems necessary to prevent the arbitration from
                  being cost-prohibitive. There will be no right or authority
                  for any claims to be arbitrated on a class action or
                  representative basis. YOU UNDERSTAND AND AGREE THAT, BY
                  ACCEPTING THESE TERMS AND CONDITIONS, YOU AND HUDI ARE EACH
                  WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
                  CLASS ACTION WITH RESPECT TO THE CLAIMS COVERED BY THIS
                  MANDATORY ARBITRATION PROVISION. You are thus giving up your
                  right to go to court to assert or defend your rights. Your
                  rights will be determined by a neutral arbitrator, and not a
                  judge or jury.
                </Text>

                <Text sx={textStyle} my="32px">
                  All claims you bring against HUDI must be resolved in
                  accordance with this arbitration provision. All claims filed
                  or brought by you contrary to this provision will be
                  considered improperly filed and void. Should you file a claim
                  contrary to this arbitration provision, HUDI will notify you
                  in writing of the improperly filed claim, and you must
                  promptly withdraw the claim. If you fail to promptly withdraw
                  the claim after receiving written notice from HUDI, HUDI may
                  recover its reasonable attorneys’ fees and costs incurred to
                  enforce this arbitration provision.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  Notice; Process
                </Heading>

                <Text sx={textStyle} mb="32px">
                  A party who intends to seek arbitration must first send a
                  written notice of the dispute to the other, by certified mail,
                  Federal Express, UPS, or Express Mail (signature required), or
                  in the event that we do not have a physical address on file
                  for you, by electronic mail ("Notice"). The Notice must (1)
                  describe the nature and basis of the claim or dispute; and (2)
                  set forth the specific relief sought ("Demand"). We agree to
                  use good faith efforts to resolve the claim directly, but if
                  we do not reach an agreement to do so within 30 days after the
                  Notice is received, you or HUDI may commence an arbitration
                  proceeding. During the arbitration, the amount of any
                  settlement offer made by you or HUDI shall not be disclosed to
                  the arbitrator until after the arbitrator makes a final
                  decision and award, if any. All documents and information
                  disclosed in the course of the arbitration shall be kept
                  strictly confidential by the recipient and shall not be used
                  by the recipient for any purpose other than for purposes of
                  the arbitration or the enforcement of the arbitrator’s
                  decision and award and shall not be disclosed except in
                  confidence to persons who have a need to know for such
                  purposes or as required by applicable law.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  Enforceability
                </Heading>

                <Text sx={textStyle} mb="32px">
                  If any part of this arbitration provision is deemed to be
                  invalid, unenforceable or illegal, or otherwise conflicts with
                  the Rules and Procedures established by AAA, then the balance
                  of this arbitration provision shall remain in effect and shall
                  be construed in accordance with its terms as if the invalid,
                  unenforceable, illegal or conflicting provision was not
                  contained herein.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="14">
                <Heading sx={headerStyle}>14. Miscellaneous</Heading>

                <Heading
                  sx={textStyle}
                  fontFamily={"avertape-semibold"}
                  mt="16px"
                >
                  General Compliance with Laws
                </Heading>

                <Text sx={textStyle} mb="32px">
                  You shall comply with all applicable laws, statutes,
                  ordinances and regulations regarding your use of the Services.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  Headings
                </Heading>

                <Text sx={textStyle} mb="32px">
                  Headings are for reference purposes only and in no way define,
                  limit, construe or describe the scope or extent of such
                  section.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  Entire Agreement
                </Heading>

                <Text sx={textStyle} mb="32px">
                  This Agreement constitutes the entire agreement between HUDI
                  and you concerning the subject matter hereof, and they may
                  only be modified by a written amendment signed by an
                  authorized agent/employee of HUDI, or by the posting by HUDI
                  of a revised version.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  Governing Law
                </Heading>

                <Text sx={textStyle} mb="32px">
                  The terms of this Agreement shall be construed in accordance
                  with the substantive laws of the State of Delaware, United
                  States of America, without giving effect to the principles of
                  conflict or choice of law of such state. The original form of
                  this Agreement has been written in English. The parties hereto
                  waive any statute, law, or regulation that might provide an
                  alternative law or forum or might require this Agreement to be
                  written in any language other than English. This Agreement
                  expressly excludes the United Nations Convention on Contracts
                  for the International Sale of Goods.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  Severability
                </Heading>

                <Text sx={textStyle} mb="32px">
                  If any part of this Agreement is held invalid or
                  unenforceable, that part will be struck, and the remaining
                  portions will remain in full force and effect.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  Waiver
                </Heading>

                <Text sx={textStyle} mb="32px">
                  A waiver by either party of any term or condition of this
                  Agreement or any breach thereof, in any one instance, will not
                  waive such term or condition or any subsequent breach thereof.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  Assignment
                </Heading>

                <Text sx={textStyle} mb="32px">
                  You may not assign your rights under this Agreement without
                  written consent from HUDI; HUDI may assign its rights under
                  this Agreement without condition. This Agreement will be
                  binding upon and will inure to the benefit of the parties,
                  their successors and permitted assigns.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  Termination
                </Heading>

                <Text sx={textStyle} mb="32px">
                  HUDI may immediately issue a warning, temporarily suspend,
                  indefinitely suspend or terminate your use of all or any
                  portion of the Services if you breach the Terms, or at any
                  time or for any reason, at the sole discretion of HUDI.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  No Relationship
                </Heading>

                <Text sx={textStyle} mb="32px">
                  No joint venture, partnership, employment, or agency
                  relationship exist between you, HUDI or any third party
                  provider as a result of this Agreement or use of the Service.
                </Text>

                <Heading sx={textStyle} fontFamily={"avertape-semibold"}>
                  Force Majeure
                </Heading>

                <Text sx={textStyle} mb="32px">
                  HUDI shall not be liable for any delay or failure to perform
                  in connection with any Services, Service or in the performance
                  of any obligation hereunder, if such delay or failure is due
                  to or in any manner caused by acts of God, rebellion, riots,
                  hijacking, insurrection, civil commotion, strikes or labor
                  disputes, fires, floods, laws, regulations, acts, demands or
                  and orders of any government or agency, or any other cause
                  which is beyond the control of HUDI. HUDI is hereby released
                  by you from any claim or demand for any direct or
                  consequential damages arising out of failure to perform as a
                  result of a force majeure event whether or not herein
                  enumerated or other event referenced above.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="15">
                <Heading sx={headerStyle}>15. Contacting HUDI</Heading>

                <Text sx={textStyle} my="16px">
                  If you have any questions about these Terms of Service, please
                  contact HUDI at
                </Text>

                <Heading sx={textStyle} mb="32px">
                  support@gethudi.com
                </Heading>
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </MainContainer>
    </Box>
  );
};
