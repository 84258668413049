import {
  Box,
  Heading,
  Text,
  Image,
  Grid,
  GridItem,
  Flex,
} from "@chakra-ui/react";

import { MainContainer } from "../../layout";

import HabitCommit from "../../assets/images/habitCommit.png";

export const Commit = () => {
  const calculatedHeight3 = `calc(50vh - 5rem - 38px)`;
  const calculatedHeight4 = `calc(60vh - 5rem - 38px)`;

  return (
    <Box>
      <MainContainer bg="brand.purple">
        <Grid
          gridTemplateColumns={{ base: "1fr", lg: "1fr 1fr" }}
          gap={"32px"}
          pt={{ base: "60px", lg: "0px" }}
        >
          <GridItem
            alignContent={"center"}
            alignItems={"center"}
            alignSelf={"center"}
            px={{ base: 0, lg: "5vw" }}
          >
            <Heading
              fontSize={{ base: "32px", lg: "60px" }}
              color="brand.black"
              mb="10px"
            >
              Commit to a habit or routine
            </Heading>

            <Text fontSize={{ base: "1rem", xl: "1.2rem" }} color="brand.gray">
              Commit to a habit based on your current spiritual level or where
              you’d like to be and build remarkable consistency over time.
            </Text>
          </GridItem>

          <GridItem>
            <Flex
              align={{base: "flex-start", lg: "center"}}
              justify="center"
              h={{ base: calculatedHeight4, sm: "620px" }}
              w={"auto"}
              overflow={"hidden"}
            >
              <Image
                src={HabitCommit}
                alt="phone"
                objectFit={"cover"}
                h={{ base: "140%", sm: "140%" }}
                w={"auto"}
              />
            </Flex>
          </GridItem>
        </Grid>
      </MainContainer>
    </Box>
  );
};
