import { Helmet } from "react-helmet";

import { Mission } from "../features/why-hudi";

export const WhyHudi = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hudi | Why Hudi</title>
        <meta name="description" content={"Reasons to use Hudi app"} />
      </Helmet>

      <Mission />
    </div>
  );
};
