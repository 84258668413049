import { ChakraProvider } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

import { theme } from "./config/theme";
import { WebRoutes } from "./routes";
import "./App.css";

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <meta property="og:title" content={"Hudi - Build habits that lead to Jannah"} />
        <meta property="twitter:title" content={"Hudi - Build habits that lead to Jannah"} />
        <meta
          property="twitter:description"
          content={
            "Hudi helps you build daily spiritual habits as a Muslim, starting with your daily salah."
          }
        />
        <meta
          property="og:description"
          content={
            "Hudi helps you build daily spiritual habits as a Muslim, starting with your daily salah."
          }
        />
        <meta property="description" content={"Hudi - Build habits that lead to Jannah"} />
        <meta property="og:image" content={"./assets/images/og.png"} />
        <meta property="og:url" content={"https://gethudi.com"} />
        <meta property="og:type" content="website" />
        <meta
          property="og:keywords"
          content="Hudi, Hudi app, Gethudi, Muslim habits, Islamic habits, Spiritual habits app, Muslim spiritual habits, Spiritual habits for Muslims, Islamic habits, Islamic daily habit app, Muslim spiritual guide, Islamic habit-building app, Muslim habit tracker, Muslim habit tracker app, Islamic spiritual growth, Muslim daily routine, Islam habit tracker,   Islamic reminders, Islamic app, Muslim app, Spiritual Muslim app, Islamic habit-building, Muslim lifestyle app, Daily Muslim practices, Islamic personal development, Prayer habit app, Islamic productivity, Salah, Dhikr, Quran, Dua, Tahajjud, Tahajud, Salawat"
          />
        <meta
          property="keywords"
          content="Hudi, Hudi app, Gethudi, Muslim habits, Islamic habits, Spiritual habits app, Muslim spiritual habits, Spiritual habits for Muslims, Islamic habits, Islamic daily habit app, Muslim spiritual guide, Islamic habit-building app, Muslim habit tracker, Muslim habit tracker app, Islamic spiritual growth, Muslim daily routine, Islam habit tracker,   Islamic reminders, Islamic app, Muslim app, Spiritual Muslim app, Islamic habit-building, Muslim lifestyle app, Daily Muslim practices, Islamic personal development, Prayer habit app, Islamic productivity, Salah, Dhikr, Quran, Dua, Tahajjud, Tahajud, Salawat"
          />
      </Helmet>

      <WebRoutes />
    </ChakraProvider>
  );
}

export default App;
