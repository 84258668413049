import { useEffect, useState, useRef } from "react";
import { Box, Heading, Text, Grid, GridItem, Link } from "@chakra-ui/react";
import { ListItem, UnorderedList, OrderedList } from "@chakra-ui/react";

import { MainContainer } from "../../layout";

const textStyle = {
  fontSize: "1.2rem",
  color: "brand.gray",
  fontWeight: 500,
  "@media only screen and (max-width: 992px)": {
    fontSize: "1rem", // Adjust font size for smaller screens
  },
};

const headerStyle = {
  fontSize: "2rem",
  color: "brand.black",
  "@media only screen and (max-width: 992px)": {
    fontSize: "1.5rem", // Adjust font size for smaller screens
  },
};

const scrollbarStyles = {
  /* Enable scrollbar appearance */
  "&::-webkit-scrollbar": {
    width: "5px", // Customize width
  },
  /* Scrollbar track styles */
  "&::-webkit-scrollbar-track": {
    backgroundColor: "transparent", // Customize background color
  },
  /* Scrollbar thumb styles */
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#eee", // Customize thumb color
    borderRadius: "6px", // Customize corner radius
  },
};

const headers = [
  {
    id: 1,
    title: "What does this privacy statement cover?",
  },
  {
    id: 2,
    title:
      "What personal, user and product data does HUDI collect and how does HUDI use and share the personal data it collects?",
  },
  {
    id: 3,
    title: "Does HUDI use cookies and related technologies on its websites?",
  },
  {
    id: 4,
    title: "Does HUDI collect personal data from children?",
  },
  {
    id: 5,
    title: "How long does HUDI retain the personal data it collects?",
  },
  {
    id: 6,
    title: "Does HUDI sell personal data?",
  },
  {
    id: 7,
    title: "Does HUDI transfer personal data across borders?",
  },
  {
    id: 8,
    title: "How does HUDI protect personal data?",
  },
  {
    id: 9,
    title: "How can I review, correct, amend or delete my personal data?",
  },
  {
    id: 10,
    title: "How can I contact HUDI?",
  },
];

export const Content = () => {
  const [activeHeader, setActiveHeader] = useState<number>(0);
  const headerRefs = useRef<any>([]); // Array to store header element refs

  const handleScroll = () => {
    const activeHeaderId = headerRefs.current.find((el: any) => {
      const rect = el?.getBoundingClientRect();
      const isInView = rect?.top >= 0 && rect?.top <= window.innerHeight;
      return isInView;
    })?.id;

    if (activeHeaderId !== activeHeader) {
      setActiveHeader(activeHeaderId);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // console.log(activeHeader);

  return (
    <Box py="40px">
      <MainContainer bg="brand.white">
        <Grid
          gridTemplateColumns={{ base: "1fr", xl: "5fr 13fr 2fr" }}
          gap={"48px"}
        >
          <GridItem
            display={{ base: "none", xl: "block" }}
            pt="36px"
            fontSize={"0.85rem"}
          >
            <Box
              w="100%"
              h="400px"
              pt="12px"
              overflowY={"scroll"}
              px="6px"
              pos={"sticky"}
              top="0"
              left="0"
              sx={scrollbarStyles}
            >
              <Text color="brand.gray">TABLE OF CONTENTS</Text>

              <OrderedList spacing={"16px"} mt="16px">
                {headers.map((header) => (
                  <ListItem key={header.id} className={String(header.id)}>
                    <Link
                      href={`#${header.id}`}
                      fontWeight={
                        Number(activeHeader) === header.id ? "bold" : "normal"
                      }
                    >
                      {header.title}
                    </Link>
                  </ListItem>
                ))}
              </OrderedList>
            </Box>
          </GridItem>

          <GridItem alignContent={"center"}>
            <Box
              borderBottomWidth={"1px"}
              borderColor={"brand.lightgray"}
              mb="40px"
            >
              <Heading
                fontSize={{ base: "2.25rem", lg: "60px" }}
                color="brand.black"
                mb={{ lg: "10px" }}
              >
                Privacy Statement
              </Heading>

              <Heading sx={textStyle} mb="24px">
                This privacy statement was last updated on August 30, 2021.
              </Heading>

              <Text sx={textStyle} mb="48px">
                HUDI, INC. (“HUDI”) values your privacy. To learn more about
                what personal and user data HUDI collects, uses, retains and
                shares; how we protect your personal data; and what choices and
                means of control you have over your personal data, please read
                this statement in its entirety.
              </Text>

              <Text sx={textStyle} mb="50px">
                If we make important changes to this privacy statement, we will
                post a notice on our website and/or contact our customers as
                appropriate or as required by applicable law.
              </Text>
            </Box>

            <Box>
              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="1">
                <Heading sx={headerStyle}>
                  1. What does this privacy statement cover?
                </Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  This statement describes HUDI’s customer privacy practices for
                  our HUDI-branded global websites, HUDI services, HUDI customer
                  sales and marketing activities, and HUDI customer services and
                  support functions, among others. This statement does not apply
                  to HUDI’s employee data practices.
                </Text>

                <Text sx={textStyle} mb="20px">
                  Please also note that certain HUDI websites, or software
                  experiences may have links to third-party websites or
                  services. In most cases, those third-party resources are
                  subject to the privacy practices and policies of those third
                  parties and not HUDI.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="2">
                <Heading sx={headerStyle}>
                  2. What personal, user and product data does HUDI collect and
                  how does HUDI use and share the personal data it collects?
                </Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  HUDI may collect certain categories of data about you when you
                  use our websites; use our services; receive our marketing
                  communications; interact with our customer service and support
                  teams; and in other contexts. The data we collect can be
                  broadly categorized as follows:
                </Text>

                <UnorderedList spacing={"20px"} mb="32px" pl="30px">
                  <ListItem>
                    <Text sx={textStyle}>
                      <span style={{ fontWeight: 700 }}>Personal Data: </span>
                      This is individually identifiable data that you may choose
                      to provide us. This may include basic information such as
                      your email address and phone number, or more sensitive
                      information such as your full date of birth or your
                      payment information.
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      <span style={{ fontWeight: 700 }}>User Data: </span>
                      This is data that we collect from users of our services.
                      User data could include personal data such as your unique
                      HUDI user ID or account username and password, but
                      sometimes this user data is anonymous or “pseudonymous”,
                      i.e., certain user data that could be made identifiable if
                      associated with personal data.
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      <span style={{ fontWeight: 700 }}>Location Data: </span>
                      This is data we collect that help us determine a location
                      based on your IP address in order to customize our Service
                      and for other uses consistent with this Privacy Statement.
                    </Text>
                  </ListItem>
                </UnorderedList>

                <Text sx={textStyle} mb="32px">
                  HUDI has various uses for your personal and user data you
                  provide to us. Here are the principal contexts in which we may
                  use, retain and share your personal and user data:
                </Text>

                <UnorderedList spacing={"20px"} mb="32px" pl="30px">
                  <ListItem>
                    <Text sx={textStyle}>
                      <span style={{ fontWeight: 700 }}>
                        Websites and Account Management:{" "}
                      </span>
                      Most often, you may browse our websites without providing
                      any personal data to us. However, if you choose to
                      establish a user account with HUDI, engage in e-commerce,
                      sign up for marketing communications, or interact with our
                      customer service teams or participate in a forum on our
                      website, we will likely need to collect some personal or
                      user data from you. In addition, our web servers
                      automatically collect certain data by default, such as IP
                      addresses and other activity logs. This type of data is
                      used for website security and site improvement purposes.
                      Much of that data is not readily identifiable and is
                      usually kept anonymous. In order to maintain and improve
                      our websites, HUDI uses third party hosting, content
                      management and security partners, vendors and suppliers.
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      <span style={{ fontWeight: 700 }}>Sales: </span>
                      If you decide to purchase a service from HUDI, we will
                      need to collect certain personal data from you, including
                      payment-related information. Typically, we use third party
                      partners, vendors and suppliers, such as payment
                      processing companies during our sales.
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      <span style={{ fontWeight: 700 }}>
                        Marketing & Social Media:{" "}
                      </span>
                      You may choose to receive HUDI marketing communications,
                      participate in an event/contest/promotion with us, or
                      connect with HUDI via social media. If you do so, we will
                      collect certain personal data about you, such as your
                      contact information, social media profile information and,
                      in order to determine your eligibility for a contest or
                      promotion, your full or partial dates of birth. HUDI may
                      share your personal data with our marketing agencies and
                      partners to carry out our marketing campaigns, contests
                      and promotions. If you wish to unsubscribe from our
                      marketing, please follow the opt-out link in the
                      communications you receive from us; update your contact
                      preferences in your HUDI account settings; or click here.
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      <span style={{ fontWeight: 700 }}>
                        Product Registration:{" "}
                      </span>
                      If you use HUDI widget services, you may choose to
                      establish an HUDI user account. An HUDI user account
                      profile may include your username, password, settings,
                      preferences, contact information, device ID information,
                      and related data. HUDI may share this information with
                      other HUDI subsidiaries and affiliates and vendors and
                      suppliers, such as third-party hosting partners. Certain
                      HUDI product settings, preference information,
                      applications history and location-based information may be
                      used by HUDI product development and improvement and
                      customer service teams if you choose to enable enhanced
                      customer support services.
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      <span style={{ fontWeight: 700 }}>
                        Customer Service & Support:{" "}
                      </span>
                      If you choose to interact with HUDI customer service and
                      support – whether it be via our website, social media,
                      chat, email or phone – HUDI customer service teams (some
                      of whom work for outsourced vendors and suppliers of HUDI)
                      may have access to your account information, product
                      information, contact information and payment information.
                      You may also be required to answer certain security
                      questions to verify your identity and your call or session
                      with HUDI customer support may be recorded for quality and
                      training purposes.
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      <span style={{ fontWeight: 700 }}>
                        Job Opportunities:{" "}
                      </span>
                      You may choose to apply for employment opportunities at
                      HUDI through the HUDI website. If you complete an
                      application for a job at HUDI, we may collect your contact
                      information, job history info and certain other
                      information that may be required or allowed under
                      applicable laws to screen candidates for employment. We
                      may share this information with other HUDI subsidiaries
                      and affiliates and vendors and suppliers who provide us
                      with candidate search and hiring services. The following
                      table provides further information on some of the specific
                      types of personal or user data we may collect from you,
                      how we use it and with whom we may share it:
                    </Text>
                  </ListItem>
                </UnorderedList>

                <Text sx={textStyle} mb="32px">
                  The following table provides further information on some of
                  the specific types of personal or user data we may collect
                  from you, how we use it and with whom we may share it:
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="3">
                <Heading sx={headerStyle}>
                  3. Does HUDI use cookies and related technologies on its
                  websites?
                </Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  Cookies are small pieces of data that are sent to your browser
                  from a web server and stored on your computer. Websites,
                  including HUDI’s sites, use cookies to help remind us who you
                  are when you visit our site; to help you navigate our site
                  during your visits; and to save preferences and shopping cart
                  contents for you so you will not have to re-enter them.
                </Text>

                <Text sx={textStyle} my="32px">
                  HUDI may also use cookies to store and help track information
                  about you, including for online advertising purposes and
                  specifically to determine which interest-based ads to display.
                  Where required by applicable law, HUDI will acquire consent
                  from you prior to using certain cookies during your HUDI
                  website experience or will offer you the opportunity to choose
                  which types of cookies to enable or disable.
                </Text>

                <Text sx={textStyle} my="32px">
                  To serve and manage cookies, and related technologies such as
                  tags and website data analytics tools, HUDI may use products
                  and services hosted by companies such as Google, Facebook and
                  Twitter. These services may collect information, such as your
                  IP address, sent by your browser or via cookies.
                </Text>

                <Text sx={textStyle} my="32px">
                  The use of such cookies is relatively standard. Most browsers
                  are initially set up to accept them. However, if you prefer
                  not to store cookies, you can choose to:
                </Text>

                <UnorderedList spacing={"20px"} mb="20px" pl="30px">
                  <ListItem>
                    <Text sx={textStyle}>not use our sites</Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      set your browser to notify you when you receive a cookie
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      set your browser to refuse to accept cookies
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      update or change your cookie preferences through our
                      website tool here.
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      delete our cookies after visiting our site
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      browse our site using your browser’s anonymous usage
                      setting (e.g., "Incognito" mode in Chrome)
                    </Text>
                  </ListItem>
                </UnorderedList>

                <Text sx={textStyle} my="32px">
                  You should also understand that some features of the HUDI
                  website might not function properly if you do not accept
                  cookies.
                </Text>

                <Text
                  sx={textStyle}
                  my="32px"
                  display={{ base: "none", md: "block" }}
                >
                  If you do not know how to control or delete cookies or would
                  like to opt-out of receiving certain targeted ads based on
                  your browsing history, you may wish to visit
                  <Link textDecoration={"underline"} fontWeight={800} mx="5px">
                    https://www.networkadvertising.org/choices/
                  </Link>
                  ,
                  <Link textDecoration={"underline"} fontWeight={800} mx="5px">
                    https://www.aboutads.info/
                  </Link>
                  , or
                  <Link textDecoration={"underline"} fontWeight={800} mx="5px">
                    https://www.youronlinechoices.eu/
                  </Link>{" "}
                  for detailed guidance, including opt-out instructions.
                </Text>

                <Text sx={textStyle} my="32px">
                  If you would like to opt-out of certain third party supported
                  Internet advertising and behavioral targeting services, please
                  visit any of the following:
                </Text>

                <UnorderedList spacing={"20px"} mb="20px" pl="30px">
                  <ListItem>
                    <Text sx={textStyle}>Google</Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>Facebook</Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>Twitter</Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>Adobe Marketing Cloud</Text>
                  </ListItem>
                </UnorderedList>

                <Text
                  sx={textStyle}
                  my="32px"
                  display={{ base: "none", md: "block" }}
                >
                  For additional information:
                  <Link textDecoration={"underline"} fontWeight={800} mx="5px">
                    https://www.consumer.ftc.gov/articles/how-protect-your-privacy-online
                  </Link>
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="4">
                <Heading sx={headerStyle}>
                  4. Does HUDI collect personal data from children?
                </Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  Our websites, products and services are intended for use by
                  parents and guardians and children, including children under
                  the age of 13. However, participants require verifiable
                  parental consent before joining. For further information,
                  please see the supplemental HUDI Child Privacy Statement
                  <Link fontWeight={800} mx="5px">
                    here.
                  </Link>
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="5">
                <Heading sx={headerStyle}>
                  5. How long does HUDI retain the personal data it collects?
                </Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  HUDI intends to retain personal data only as long as necessary
                  to complete the purposes of use described above in section 2.
                  When those purposes have been fulfilled, we will securely
                  delete personal data as appropriate. However, we may retain
                  your personal data as long as any of the following
                  circumstances apply:
                </Text>

                <UnorderedList spacing={"20px"} mb="20px" pl="30px">
                  <ListItem>
                    <Text sx={textStyle}>
                      your HUDI account or marketing subscription is active
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      to provide you with services or support that you have
                      requested
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      to comply with applicable laws, including for tax and
                      audit purposes
                    </Text>
                  </ListItem>

                  <ListItem>
                    <Text sx={textStyle}>
                      to enforce HUDI’s rights, including litigation and debt
                      collection
                    </Text>
                  </ListItem>
                </UnorderedList>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="6">
                <Heading sx={headerStyle}>
                  6. Does HUDI sell personal data?
                </Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  While HUDI may share personal data it collects pursuant to the
                  purposes described above in section 2, HUDI does not sell
                  personal data. If HUDI did decide to sell personal data at any
                  point, we would provide appropriate notice and consent
                  mechanisms to individuals as required by applicable laws.
                </Text>

                <Text sx={textStyle} my="32px">
                  In addition, please note that if HUDI or any of its businesses
                  or operations were to be sold to another entity at any point,
                  that sale could involve the transfer of personal data held by
                  HUDI to the acquiring organization. In such a circumstance,
                  HUDI would provide appropriate notice and consent mechanisms
                  to individuals as required by applicable laws.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="7">
                <Heading sx={headerStyle}>
                  7. Does HUDI transfer personal data across borders?
                </Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  HUDI may collect, process, use, retain and transfer personal
                  data across borders in order to fulfill our purposes of use as
                  described above in section 2. This may include transfers of
                  personal data to countries that may not have equivalent
                  privacy or data protection laws to those in your own country.
                </Text>

                <Text sx={textStyle} my="32px">
                  However, regardless of where we transfer personal data to,
                  HUDI takes appropriate steps to ensure that your personal data
                  enjoys consistent standards of care. This includes signing
                  contracts between HUDI affiliates and with our vendors and
                  suppliers to make sure that both parties are following
                  consistent privacy and security principles, processes and
                  protections.
                </Text>

                <Text sx={textStyle} my="32px">
                  For transfers involving European personal data, HUDI uses
                  Standard Contractual Clauses with its partners.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="8">
                <Heading sx={headerStyle}>
                  8. How does HUDI protect personal data?
                </Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  HUDI believes that privacy and security go hand-in-hand. We
                  use appropriate physical, technical, and administrative
                  controls to protect the personal data we are responsible for.
                  That includes encryption and other enhanced security measures,
                  where appropriate or required. We also take appropriate steps
                  to make sure that our vendors, suppliers and certain other
                  partners adopt consistent standards of protection for the
                  personal data that HUDI is responsible for.
                </Text>

                <Text sx={textStyle} my="32px">
                  However, it is important to note that no technology is
                  guaranteed to be invulnerable to threat or attack. Therefore,
                  please be sure to exercise care when using any website or
                  product. If technology you use is configured with privacy or
                  security controls or features, please be sure to utilize
                  those.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="9">
                <Heading sx={headerStyle}>
                  9. How can I review, correct, amend or delete my personal
                  data?
                </Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  If you have an HUDI account, you may have the ability to
                  review, correct, amend or delete your personal data through
                  your account profile. To do so, please visit your account and
                  click on “Settings”.
                </Text>

                <Text sx={textStyle} my="32px">
                  In addition, under this privacy statement and certain
                  applicable laws, you may submit a request to HUDI to review,
                  correct, amend or to delete your personal data that HUDI has
                  about you. You may also have the right to request a
                  machine-readable copy of your personal data. To submit such a
                  request to HUDI, please contact us at support@gethudi.com
                </Text>

                <Text sx={textStyle} my="32px">
                  Please note that we will review, respond to and act upon such
                  requests as soon as possible and in accordance with applicable
                  laws. We are also required to verify your identity before
                  granting you access to your personal data. If you have an HUDI
                  account, we may verify your identity by requiring you to sign
                  into your account. If you do not have an HUDI account, or an
                  email address on file with us, then we may request additional
                  limited information from you in order to verify your identity.
                  Please note that if you do not have an account with HUDI, or
                  an email address on file, or if we are unable to verify your
                  identity in our records, we may deny your request.
                </Text>

                <Text sx={textStyle} my="32px">
                  You may also use an authorized agent to submit a request under
                  this section on your behalf. If you choose to have an
                  authorized agent submit such a request on your behalf, HUDI
                  will require: (i) You to provide the authorized agent written
                  permission to do so; and (ii) your agent to verify their
                  identity directly with HUDI. HUDI may deny a request from an
                  agent that does not meet these requirements.
                </Text>

                <Text sx={textStyle} my="32px">
                  In addition, you have the right to raise questions or
                  complaints with your applicable national data protection
                  authority at any time.
                </Text>
              </Box>

              <Box py="20px" ref={(el) => headerRefs.current.push(el)} id="10">
                <Heading sx={headerStyle}>10. How can I contact HUDI?</Heading>

                <Text sx={textStyle} mb="32px" mt="16px">
                  If you have any additional questions, complaints or concerns
                  regarding privacy at HUDI. Please feel free to reach us at the
                  following:
                </Text>

                <Text sx={textStyle} my="32px">
                  Email: support@gethudi.com
                </Text>
              </Box>
            </Box>
          </GridItem>
        </Grid>
      </MainContainer>
    </Box>
  );
};
