import { Box, Heading, Text, Flex } from "@chakra-ui/react";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";

import { MainContainer } from "../../layout";

export const Mission = () => {
  const navigate = useNavigate();

  const headerStyle = {
    color: "brand.gray",
    fontStyle: "italic",
  };

  const signature = {
    fontSize: 24,
    fontFamily: "segoe-script",
    color: "brand.gray",
  };

  return (
    <Box pt="20px">
      <MainContainer bg="brand.white">
        <Flex justify={"flex-end"}>
          <Flex
            w={"32px"}
            h={"32px"}
            borderRadius={"14px"}
            bg={"brand.lightgray"}
            alignItems={"center"}
            justifyContent={"center"}
            cursor={"pointer"}
            onClick={() => navigate(-1)}
          >
            <SmallCloseIcon boxSize={"24px"} color={"brand.black"} />
          </Flex>
        </Flex>

        <Box px={{ base: "0px", lg: "20%" }} mt="42px">
          <Text sx={headerStyle} fontSize={{ base: "1rem", lg: "1.2rem" }}>
            <span style={{ fontWeight: 700, marginRight: 5 }}>Hudi</span>

            <span>is gotten from the Arabic word</span>

            <span style={{ fontWeight: 700, marginLeft: 5, marginRight: 5 }}>
              Huda
            </span>

            <span>meaning</span>

            <span style={{ fontWeight: 700, marginLeft: 5 }}>
              Right Guidance
            </span>

            <span>. It also means</span>

            <span style={{ fontWeight: 700, marginLeft: 5 }}>
              one who is guided to the right path...
            </span>
          </Text>

          <Heading
            fontSize={{ base: "1.75rem", lg: "3rem" }}
            color={"brand.black"}
            my="36px"
          >
            One mission: Make it easier and more enjoyable for Muslims to get
            closer to Allah, one day at a time.
          </Heading>

          <Flex gap="2rem" flexDir={"column"}>
            <Text
              fontSize={{ base: "1rem", lg: "1.2rem" }}
              color={"brand.gray"}
            >
              When it comes to our spiritual practices as Muslims, we sometimes
              struggle with doing and sticking to them and lack the motivation,
              energy, and focus to be consistent daily. There is the excuse of
              being busy or lazy, little or no knowledge or understanding of our
              religion, a wrong perception of the purpose and benefits of these
              practices, not having the right intentions, a lack of proper
              planning, guidance, and support, and a disconnection from Allah
              and ourselves.
            </Text>

            <Text
              fontSize={{ base: "1rem", lg: "1.2rem" }}
              color={"brand.gray"}
            >
              These practices then take a back seat in our lives and become
              something we do when we can, when we like, or not at all. And even
              when we do them, we delay, rush, and are not fully present because
              it feels like a burden or something we have to get out of the way
              to move on to other things.
            </Text>

            <Text
              fontSize={{ base: "1rem", lg: "1.2rem" }}
              color={"brand.gray"}
            >
              Hudi wants to change that by helping you build spiritual habits
              that are doable, irresistible, satisfying, and easily integrated
              into your daily life, so it’s easy to stay motivated, that no
              matter what happens, no matter how stressful it gets, you will
              make an effort, no matter how small and continue to want to do it,
              every day. Getting closer to Allah shouldn't feel like a hard task
              or burden but an enjoyable spiritual experience to look forward
              to, making it an integral part of your day and your journey
              through life.
            </Text>

            <Text
              fontSize={{ base: "1rem", lg: "1.2rem" }}
              color={"brand.gray"}
            >
              We hope that every Muslim that uses Hudi will actively work
              towards getting closer to Allah every day. We know that it is a
              huge dream and we pray that Allah makes it easy and guides us all
              to the straight path, Ameen.
            </Text>
          </Flex>

          <Flex gap="0.5rem" flexDir={"column"} my="36px">
            <Text sx={signature}>Salam,</Text>
            <Text sx={signature}>Your friend in deen,</Text>
            <Text sx={signature}>Fari</Text>
          </Flex>
        </Box>
      </MainContainer>
    </Box>
  );
};
